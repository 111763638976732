import "./index.css"

import React from "react"
import ReactDOM from "react-dom"

import App from "./App"
import reportWebVitals from "./reportWebVitals"

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
)
if (process.env.NODE_ENV === "production") {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
