const tags = [
  { id: 1, name: "Kultur" },
  { id: 2, name: "Sport" },
  { id: 3, name: "Werkzeug" },
  { id: 4, name: "Gesellschaft" },
  { id: 5, name: "Technik" },
  { id: 6, name: "Sonstiges" },
  { id: 7, name: "Fußball" },
  { id: 8, name: "Turnen" },
  { id: 9, name: "Klettern" },
  { id: 10, name: "Tischtennis" },
  { id: 11, name: "Tennis" },
  { id: 12, name: "Volleyball" },
  { id: 13, name: "Basketball" },
  { id: 14, name: "Handball" },
  { id: 15, name: "Leichtathletik" },
  { id: 16, name: "Schwimmen" },
  { id: 17, name: "Eishockey" },
  { id: 18, name: "Tanzen" },
  { id: 19, name: "Kunst" },
  { id: 20, name: "Musik" },
  { id: 21, name: "Theater" },
  { id: 22, name: "Kino" },
  { id: 23, name: "Bücher" },
  { id: 24, name: "Kochen" },
  { id: 25, name: "Garten" },
  { id: 26, name: "Bauen" },
  { id: 27, name: "Fahrrad" },
  { id: 28, name: "Games" },
  { id: 29, name: "Computer" },
]

export default tags
